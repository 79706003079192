.link {
  color: #499df2;
  border-bottom: 1px solid #499df2;
  -webkit-transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out, -webkit-box-shadow .25s ease-out;
  transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out, -webkit-box-shadow .25s ease-out;
  transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out, box-shadow .25s ease-out;
  transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out, box-shadow .25s ease-out, -webkit-box-shadow .25s ease-out
}

.link,
.link:hover {
  text-decoration: none
}

.link:hover {
  -webkit-transition: color 0s, background-color 0s, border-color 0s, -webkit-box-shadow 0s;
  transition: color 0s, background-color 0s, border-color 0s, -webkit-box-shadow 0s;
  transition: color 0s, background-color 0s, border-color 0s, box-shadow 0s;
  transition: color 0s, background-color 0s, border-color 0s, box-shadow 0s, -webkit-box-shadow 0s;
  color: #ff3c41;
  border-bottom: 1px solid #ff3c41
}

.link_underline_none,
.link_underline_none:hover {
  border-bottom: none
}

.link_color_black {
  color: #3c3c3c
}

.link_color_black:hover {
  color: #ff3c41
}
