.h1,
h1 {
  margin: 0;
  padding: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h1,
  h1 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: .2px
  }
}

.h2,
h2 {
  margin: 0;
  padding: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h2,
  h2 {
    margin: 0 0 16px;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: .2px
  }
}

.h3,
h3 {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h3,
  h3 {
    margin: 0 0 16px;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: .2px
  }
}

.h4,
h4 {
  margin: 0 0 16px;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h4,
  h4 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: .2px
  }
}
