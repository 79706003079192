.logo__handler {
  margin-right:16px
}
.logo__handler:hover {
  cursor:pointer
}
@media (max-width:991px) {
  .logo {
    text-align:center
  }
}
