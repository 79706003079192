.input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.input {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  font-family: Gilroy, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  text-decoration: none;
  border-radius: 4px;
  text-align: left;
  -webkit-transition: all .48s;
  transition: all .48s;
  border: 1px solid #b4b4b4;
  color: #3c3c3c
}

.input::-webkit-input-placeholder {
  color: #b4b4b4
}

.input:-ms-input-placeholder,
.input::-ms-input-placeholder {
  color: #b4b4b4
}

.input::placeholder {
  color: #b4b4b4
}

.input:hover {
  -webkit-transition: all .24s;
  transition: all .24s;
  cursor: pointer
}

.input:focus,
.input:hover {
  outline: none;
  border: 1px solid #b4b4b4
}

.input:focus::-webkit-input-placeholder {
  color: #edeeed
}

.input:focus:-ms-input-placeholder,
.input:focus::-ms-input-placeholder {
  color: #edeeed
}

.input:focus::placeholder {
  color: #edeeed
}

.input_type_material {
  border: none;
  padding: 0;
  border-bottom: 1px solid #b4b4b4;
  border-radius: 0
}

.input_type_material:hover {
  -webkit-transition: all .24s;
  transition: all .24s;
  cursor: pointer
}

.input_type_material:focus,
.input_type_material:hover {
  outline: none;
  border: none;
  border-bottom: 1px solid #b4b4b4
}

.input_color_secondary,
.input_color_secondary:focus,
.input_color_secondary:hover {
  border-color: #499df2
}

.input_size_hero {
  font-size: 18px;
  padding: 20px 16px;
  border-radius: 4px
}
