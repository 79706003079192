.nav {
  list-style-type:none
}
@media (max-width:991px) {
  .nav {
    text-align:center
  }
}
@media (max-width:767px) {
  .nav {
    visibility:hidden;
    opacity:0;
    -webkit-transition:opacity .5s ease;
    transition:opacity .5s ease;
    height:0;
    width:0;
    margin:0;
    padding:0
  }
  .nav_is_visible {
    padding:16px;
    height:auto;
    width:auto;
    visibility:visible;
    opacity:1;
    -webkit-transition:opacity .2s ease;
    transition:opacity .2s ease
  }
}
.nav__item {
  display:inline-block;
  vertical-align:middle
}
@media (max-width:767px) {
  .nav__item {
    display:block;
    text-align:center;
    margin-bottom:24px
  }
}
