.button {
  margin: 0;
  border: none;
  background: none;
  display: inline-block;
  font-size: 16px;
  font-family: Gilroy, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .48s;
  transition: all .48s
}

.button:hover {
  cursor: pointer
}

.button_with_shadow:hover {
  -webkit-transition: all .24s;
  transition: all .24s;
  -webkit-box-shadow: 0 16px 32px 0 rgba(0, 0, 0, .2);
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, .2);
  cursor: pointer
}

.button_color_primary {
  color: #fff;
  background-color: #ff3c41
}

.button_color_secondary {
  color: #fff;
  background-color: #499df2
}

.button_color_light {
  color: #4b9bf5;
  background-color: #edf5fe;
  font-weight: 700
}

.button_color_light:hover {
  color: #ff3c41;
  background-color: #ffe0e0
}

.button_size_big {
  font-size: 24px;
  line-height: 32px;
  padding: 16px 32px
}

.button_size_small {
  font-size: 14px;
  line-height: 24px;
  padding: 4px 24px
}

.button_size_hero {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .2px;
  padding: 20px 48px
}

.button:disabled,
.button_disabled {
  background-color: #edeeed;
  color: #b4b4b4
}

.button:disabled:hover,
.button_disabled:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed
}

.button_rounded {
  border-radius: 32px
}
