/* UP

/* Extra small devices (portrait phones, less than 576px) %/
/* No media query since this is the default in Bootstrap

/* Small devices (landscape phones, 576px and up) %/
@custom-media --up-xs-devices (min-width: 576px);

/* Medium devices (tablets, 768px and up) %/
@custom-media --up-md-devices (min-width: 768px);

/* Large devices (desktops, 992px and up) %/
@custom-media --up-lg-devices (min-width: 992px);

/* Extra large devices (large desktops, 1200px and up) %/
@custom-media --up-xl-devices (min-width: 1200px);


/* DOWN

/* Extra small devices (portrait phones, less than 576px) */
@custom-media --down-xs-devices (max-width: 575px);

/* Small devices (landscape phones, less than 768px) */
@custom-media --down-sm-devices (max-width: 767px);

/* Medium devices (tablets, less than 992px) */
@custom-media --down-md-devices (max-width: 991px);

/* Large devices (desktops, less than 1200px) */
@custom-media --down-lg-devices (max-width: 1199px);

/* Extra large devices (large desktops) */
/* No media query since the extra-large breakpoint has no upper bound on its width

/* ONLY

/* Extra small devices (portrait phones, less than 576px) */
@custom-media --only-xs-devices (max-width: 575px);

/* Small devices (landscape phones, 576px and up) */
@custom-media --only-sm-devices (min-width: 576px) and (max-width: 767px);

/* Medium devices (tablets, 768px and up) */
@custom-media --only-md-devices (min-width: 768px) and (max-width: 991px);

/* Large devices (desktops, 992px and up) */
@custom-media --only-lg-devices (min-width: 992px) and (max-width: 1199px);

/* Extra large devices (large desktops, 1200px and up) */
@custom-media --only-xlg-devices (min-width: 1200px);
