.human:after {
  display:table;
  clear:both;
  content:""
}
.human__image {
  width:54px;
  height:54px;
  border-radius:100%;
  display:block;
  margin:0 auto
}
.human__name {
  margin-top:24px;
  display:block
}
