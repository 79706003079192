.glide {
  position: relative;
  width: 100%;
  height: 100%;
}
.glide__wrapper {
  overflow: hidden;
}
.glide__track {
  position: relative;
  width: 100%;
  height: 100%;
  list-style: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -ms-touch-action: pan-Y;
      touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
}
.glide__track.dragging {
  cursor: grabbing;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.glide__slide {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.glide__arrow {
  cursor: pointer;
}
.glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.glide--slider .glide__slide {
  float: left;
  clear: none;
}
.glide--carousel.glide--vertical .glide__track {
  overflow: visible;
}
.glide--carousel .glide__slide {
  float: left;
  clear: none;
}
.glide--slideshow .glide__wrapper {
  height: 100%;
}
.glide--slideshow .glide__slide {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.glide--slideshow .glide__slide.active {
  z-index: 1;
}
