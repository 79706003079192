.h-text_size_mega {
  font-size: 64px
}

.h-text_size_jumbo,
.h-text_size_mega {
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .2px
}

.h-text_size_jumbo {
  font-size: 48px
}

.h-text_size_display {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .2px
}

.h-text_size_headline {
  font-size: 24px;
  line-height: 1.33
}

.h-text_size_headline,
.h-text_size_title {
  margin: 0 0 16px;
  padding: 0;
  font-weight: 700;
  letter-spacing: .2px
}

.h-text_size_title {
  font-size: 20px;
  line-height: 1.2
}

.h-text_size_subheader {
  font-weight: 700
}

.h-text_size_body,
.h-text_size_subheader {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: .2px
}

.h-text_size_body {
  font-weight: 500;
  color: #787878
}

.h-text_size_caption {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #b4b4b4 !important;
  letter-spacing: .2px
}

.h-text_resize_mega {
  margin: 0;
  padding: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h-text_resize_mega {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: .2px
  }
}

.h-text_resize_jumbo {
  margin: 0;
  padding: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h-text_resize_jumbo {
    margin: 0 0 16px;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: .2px
  }
}

.h-text_resize_display {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h-text_resize_display {
    margin: 0 0 16px;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: .2px
  }
}

.h-text_resize_headline {
  margin: 0 0 16px;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: .2px
}

@media (max-width: 767px) {
  .h-text_resize_headline {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: .2px
  }
}

.h-text_resize_title {
  margin: 0 0 16px;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .2px
}

.h-text_resize_subheader {
  font-weight: 700
}

.h-text_resize_body,
.h-text_resize_subheader {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: .2px
}

.h-text_resize_body {
  font-weight: 500;
  color: #787878
}

.h-text_resize_caption {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #b4b4b4 !important;
  letter-spacing: .2px
}

.h-text_color_primary {
  color: #ff3c41
}

.h-text_color_secondary {
  color: #499df2
}

.h-text_color_gray-dark {
  color: #b4b4b4
}

.h-text_color_gray {
  color: #edeeed
}

.h-text_color_gray-light {
  color: #f8f8f8
}

.h-text_color_white {
  color: #fff
}

.h-text_align_left {
  text-align: left
}

.h-text_align_right {
  text-align: right
}

.h-text_align_center {
  text-align: center
}

.h-text_weight_normal {
  font-weight: 400
}

.h-text_weight_bold {
  font-weight: 700 !important
}
