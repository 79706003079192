.circle {
  width:360px;
  height:360px;
  border-radius:100%;
  overflow:hidden
}
@media (max-width:991px) {
  .circle {
    width:240px;
    height:240px
  }
}
.circle_centered {
  margin:0 auto
}
.circle_align_left {
  margin-left:48px
}
.circle_align_right {
  margin-right:48px
}
.circle_small {
  width:240px;
  height:240px;
  margin-bottom:24px
}
.circle_big {
  width:480px;
  height:480px;
  margin-bottom:24px
}
@media (max-width:991px) {
  .circle_big {
    width:240px;
    height:240px
  }
}
