.h-padding_none {
  padding: 0
}

.h-padding_top_extrasmall {
  padding-top: 8px
}

.h-padding_top_small {
  padding-top: 16px
}

.h-padding_top_medium {
  padding-top: 24px
}

.h-padding_top_mediumlarge {
  padding-top: 32px
}

.h-padding_top_large {
  padding-top: 48px
}

.h-padding_top_extralarge {
  padding-top: 64px
}

.h-padding_bottom_none {
  padding-bottom: 0
}

.h-padding_bottom_extrasmall {
  padding-bottom: 8px
}

.h-padding_bottom_small {
  padding-bottom: 16px
}

.h-padding_bottom_medium {
  padding-bottom: 24px
}

.h-padding_bottom_mediumlarge {
  padding-bottom: 32px
}

.h-padding_bottom_large {
  padding-bottom: 48px
}

.h-padding_bottom_extralarge {
  padding-bottom: 64px
}

.h-padding_left_none {
  padding-left: 0
}

.h-padding_left_extrasmall {
  padding-left: 8px
}

.h-padding_left_small {
  padding-left: 16px
}

.h-padding_left_medium {
  padding-left: 24px
}

.h-padding_left_mediumlarge {
  padding-left: 32px
}

.h-padding_left_large {
  padding-left: 48px
}

.h-padding_left_extralarge {
  padding-left: 64px
}

.h-padding_right_none {
  padding-right: 0
}

.h-padding_right_extrasmall {
  padding-right: 8px
}

.h-padding_right_small {
  padding-right: 16px
}

.h-padding_right_medium {
  padding-right: 24px
}

.h-padding_right_mediumlarge {
  padding-right: 32px
}

.h-padding_right_large {
  padding-right: 48px
}

.h-padding_right_extralarge {
  padding-right: 64px
}

.h-padding_around_none {
  padding: 0
}

.h-padding_around_extrasmall {
  padding: 8px
}

.h-padding_around_small {
  padding: 16px
}

.h-padding_around_medium {
  padding: 24px
}

.h-padding_around_mediumlarge {
  padding: 32px
}

.h-padding_around_large {
  padding: 48px
}

.h-padding_around_extralarge {
  padding: 64px
}

.h-padding_vertical_none {
  padding-top: 0;
  padding-bottom: 0
}

.h-padding_vertical_extrasmall {
  padding-top: 8px;
  padding-bottom: 8px
}

.h-padding_vertical_small {
  padding-top: 16px;
  padding-bottom: 16px
}

.h-padding_vertical_medium {
  padding-top: 24px;
  padding-bottom: 24px
}

.h-padding_vertical_mediumlarge {
  padding-top: 32px;
  padding-bottom: 32px
}

.h-padding_vertical_large {
  padding-top: 48px;
  padding-bottom: 48px
}

.h-padding_vertical_extralarge {
  padding-top: 64px;
  padding-bottom: 64px
}

.h-padding_horizontal_none {
  padding-left: 0;
  padding-right: 0
}

.h-padding_horizontal_extrasmall {
  padding-left: 8px;
  padding-right: 8px
}

.h-padding_horizontal_small {
  padding-left: 16px;
  padding-right: 16px
}

.h-padding_horizontal_medium {
  padding-left: 24px;
  padding-right: 24px
}

.h-padding_horizontal_mediumlarge {
  padding-left: 32px;
  padding-right: 32px
}

.h-padding_horizontal_large {
  padding-left: 48px;
  padding-right: 48px
}

.h-padding_horizontal_extralarge {
  padding-left: 64px;
  padding-right: 64px
}
