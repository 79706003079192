.glide__arrows {
  position: absolute;
}
.glide--horizontal .glide__arrows {
  top: 50%;
  width: 92%;
  margin-left: 4%;
}
.glide--vertical .glide__arrows {
  left: 50%;
  height: 88%;
  margin-top: 6%;
}
.glide__arrow {
  position: absolute;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  font: 11px Arial, sans-serif;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 1;
  -webkit-transition: opacity 150ms ease, border 300ms ease-in-out;
          transition: opacity 150ms ease, border 300ms ease-in-out;
}
.glide__arrow.disabled {
  opacity: 0.33;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide--horizontal .glide__arrow {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.glide--horizontal .glide__arrow.prev {
  left: 0;
}
.glide--horizontal .glide__arrow.next {
  right: 0;
}
.glide--vertical .glide__arrow {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.glide--vertical .glide__arrow.prev {
  top: 0;
}
.glide--vertical .glide__arrow.next {
  bottom: 0;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  list-style: none;
}
.glide--horizontal .glide__bullets {
  bottom: 8%;
  left: 0;
  width: 100%;
  height: 12px;
  text-align: center;
}
.glide--vertical .glide__bullets {
  top: 50%;
  right: 8%;
  width: 12px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.glide__bullets > * {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.5);
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid transparent;
  -webkit-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
}
.glide--horizontal .glide__bullets > * {
  margin: 0 5px;
}
.glide--vertical .glide__bullets > * {
  vertical-align: middle;
}
.glide__bullets > *.active {
  background-color: white;
}
.glide__bullets > *:focus {
  outline: none;
}
.glide__bullets > *:hover,
.glide__bullets > *:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
