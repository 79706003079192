.quantity {
  position: relative;
}

.quantity__label {
  position: absolute;
  font-weight: bold;
  font-size: 9px;
  top: 6px;
  left: 12px;
}

.quantity__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 4px solid #499df2;
  font-size: 24px;
  padding: 25px 42px 7px 6px;
  border-radius: 8px;
  font-weight: 700;
  line-height: 24px;
  outline: none;
  margin-right: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 85% 74%;
}
