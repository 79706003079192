.me {
  border-radius:0
}

.me_with_background {
  background: url('../../static/content/images/me.jpg') no-repeat -60px center;
  background-size: cover;
}

@media (max-width:991px) {
  .me {
    margin:0 auto
  }
}
@media (max-width:991px) {
  .me__text {
    text-align:center
  }
}
