.back {
  background-repeat: no-repeat;
  background-size: cover;
}

.back_hero {
  background-image: url("../../static/content/background/back.png");
  background-position: top center;
}

.back_3_1 {
  background: url('../../static/content/images/3-1.jpg');
  background-position: -170px;
}
.back_3_2 {
  background: url('../../static/content/images/3-2.jpg') center;
}
.back_3_3 {
  background: url('../../static/content/images/3-3.jpg');
}

.back_with_newtosha {
  background: url('../../static/content/logo/racoon.png') center; background-size: contain;
}
.back_with_confetti {
  background-image: url('../../static/content/background/header-confetti-o.png');
  background-position: top center;
}

.back_with_book {
  background: url('../../static/content/images/book.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
