.carousel .glide__slides {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.carousel .glide__slide {
  padding: 0;
  margin: 0;
}
.carousel .glide__bullets {
  bottom: 8%;
  left: 0;
  width: 100%;
  height: 12px;
  text-align: center;
  top: 256px;
}

.carousel .glide__bullets .glide__bullet {
  background-color:#f8f8f8;
  width:12px;
  height:12px;
  margin:0 8px
}
.carousel .glide__bullets .glide__bullet--active {
  background-color:#6fb581
}
.carousel .glide__bullets .glide__bullet--active:focus,
.carousel .glide__bullets .glide__bullet--active:hover {
  border-color:#6fb581!important
}
.carousel .glide__bullets_reviews {
  top: 110px
}
