.h-margin_none {
  margin: 0
}

.h-margin_auto {
  margin-left: auto;
  margin-right: auto
}

.h-margin_top_none {
  margin-top: 0
}

.h-margin_top_extrasmall {
  margin-top: 8px
}

.h-margin_top_small {
  margin-top: 16px
}

.h-margin_top_medium {
  margin-top: 24px
}

.h-margin_top_mediumlarge {
  margin-top: 32px
}

.h-margin_top_large {
  margin-top: 48px
}

.h-margin_top_extralarge {
  margin-top: 64px
}

.h-margin_bottom_none {
  margin-bottom: 0
}

.h-margin_bottom_extrasmall {
  margin-bottom: 8px
}

.h-margin_bottom_small {
  margin-bottom: 16px
}

.h-margin_bottom_medium {
  margin-bottom: 24px
}

.h-margin_bottom_mediumlarge {
  margin-bottom: 32px
}

.h-margin_bottom_large {
  margin-bottom: 48px
}

.h-margin_bottom_extralarge {
  margin-bottom: 64px
}

.h-margin_left_none {
  margin-left: 0
}

.h-margin_left_extrasmall {
  margin-left: 8px
}

.h-margin_left_small {
  margin-left: 16px
}

.h-margin_left_medium {
  margin-left: 24px
}

.h-margin_left_mediumlarge {
  margin-left: 32px
}

.h-margin_left_large {
  margin-left: 48px
}

.h-margin_left_extralarge {
  margin-left: 64px
}

.h-margin_right_none {
  margin-right: 0
}

.h-margin_right_extrasmall {
  margin-right: 8px
}

.h-margin_right_small {
  margin-right: 16px
}

.h-margin_right_medium {
  margin-right: 24px
}

.h-margin_right_mediumlarge {
  margin-right: 32px
}

.h-margin_right_large {
  margin-right: 48px
}

.h-margin_right_extralarge {
  margin-right: 64px
}

.h-margin_around_none {
  margin: 0
}

.h-margin_around_extrasmall {
  margin: 8px
}

.h-margin_around_small {
  margin: 16px
}

.h-margin_around_medium {
  margin: 24px
}

.h-margin_around_mediumlarge {
  margin: 32px
}

.h-margin_around_large {
  margin: 48px
}

.h-margin_around_extralarge {
  margin: 64px
}

.h-margin_vertical_none {
  margin-top: 0;
  margin-bottom: 0
}

.h-margin_vertical_extrasmall {
  margin-top: 8px;
  margin-bottom: 8px
}

.h-margin_vertical_small {
  margin-top: 16px;
  margin-bottom: 16px
}

.h-margin_vertical_medium {
  margin-top: 24px;
  margin-bottom: 24px
}

.h-margin_vertical_mediumlarge {
  margin-top: 32px;
  margin-bottom: 32px
}

.h-margin_vertical_large {
  margin-top: 48px;
  margin-bottom: 48px
}

.h-margin_vertical_extralarge {
  margin-top: 64px;
  margin-bottom: 64px
}

.h-margin_horizontal_none {
  margin-left: 0;
  margin-right: 0
}

.h-margin_horizontal_extrasmall {
  margin-left: 8px;
  margin-right: 8px
}

.h-margin_horizontal_small {
  margin-left: 16px;
  margin-right: 16px
}

.h-margin_horizontal_medium {
  margin-left: 24px;
  margin-right: 24px
}

.h-margin_horizontal_mediumlarge {
  margin-left: 32px;
  margin-right: 32px
}

.h-margin_horizontal_large {
  margin-left: 48px;
  margin-right: 48px
}

.h-margin_horizontal_extralarge {
  margin-left: 64px;
  margin-right: 64px
}
