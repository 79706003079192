@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/329DD5_11_0.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/329DD5_6_0.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/329DD5_9_0.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/329DD5_5_0.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
